import React from "react"
import { StaticQuery, graphql, Link } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faHome, faPhoneAlt } from "@fortawesome/free-solid-svg-icons"
import { faQuestionCircle } from "@fortawesome/free-regular-svg-icons"

import styles from "./footer-css-modules.module.css"
import { Row, ResponsiveRow, Col, InnerWrapper } from "../Wrapper"

const Footer = () => (
    <StaticQuery
        query={graphql`
            query FooterQuery {
                allStrapiProductcategory(sort: {fields: fields___sortindexnum, order: ASC}) {
                    edges {
                        node {
                            fields {
                                sortindexnum
                            }
                            title
                        }
                    }
                }
                allStrapiProduct(sort: {fields: fields___sortindexnum, order: ASC}) {
                    edges {
                        node {
                            id
                            name
                            category
                            icontype
                            iconname
                            numofpeople
                            location
                            payment
                            risklevel
                            support
                            motorenquiry
                            showonhomepage
                            body
                            fields {
                                sortindexnum
                            }
                        }
                    }
                }
                allStrapiPage(filter: {name: {eq: "aboutUs"}}) {
                    edges {
                        node {
                            name
                            body
                        }
                    }
                }
                allStrapiContact {
                    edges {
                        node {
                            address1
                            address2
                            phone
                            postalCode
                        }
                    }
                }
            }
        `}
        render={data => {
            var showdown = require('showdown'),
                converter = new showdown.Converter();
            
            const productsByCat = data.allStrapiProductcategory.edges.map((c) => {
                let arr = [];

                data.allStrapiProduct.edges.map((p) => {
                    if (p.node.category === c.node.title) arr.push(p.node);
                });

                return arr;
            });

            return (
                <div className={styles.footerWrapper}>
                    <InnerWrapper>
                        <ResponsiveRow alignTop={true}>
                            {data.allStrapiProductcategory.edges.map((c, ci) => (
                                ci < data.allStrapiProductcategory.edges.length - 1 &&
                                <Col key={ci} className={styles.footerColWrapper}>
                                    {ci < data.allStrapiProductcategory.edges.length - 2 ?
                                        <>
                                            <a className={styles.sitemapHeader} href={`/products?category=${c.node.title}`}>
                                                <h4>{c.node.title}</h4>
                                            </a>
                                            {productsByCat[ci].map((p, pi) => (
                                                <a key={pi} className={styles.sitemapContent} href={`/product?id=${p.id}`}>
                                                    <p>{p.name}</p>
                                                </a>
                                            ))}
                                        </>
                                        :
                                        <>
                                            <a className={styles.sitemapHeader} href={`/products?category=${c.node.title}`}>
                                                <h4>{c.node.title}</h4>
                                            </a>
                                            {productsByCat[ci].map((p, pi) => (
                                                <a key={pi} className={styles.sitemapContent} href={`/product?id=${p.id}`}>
                                                    <p>{p.name}</p>
                                                </a>
                                            ))}
                                            {productsByCat[ci + 1].map((p, pi) => (
                                                <a key={pi} className={styles.sitemapHeader} href={`/product?id=${p.id}`}>
                                                    <h4>{data.allStrapiProductcategory.edges[ci + 1].node.title}</h4>
                                                </a>
                                            ))}
                                        </>
                                    }
                                </Col>
                            ))}
                        </ResponsiveRow>
                        <div className={styles.divider}></div>
                        <ResponsiveRow alignTop={true}>
                            <Col className={styles.footerColWrapper}>
                                <h3 className={styles.sectionTitle}>About Us</h3>
                                <div className={styles.sectionContent}
                                    dangerouslySetInnerHTML={{ __html: converter.makeHtml(data.allStrapiPage.edges[0].node.body) }}
                                />
                            </Col>
                            <Col className={styles.footerColWrapper}>
                                <h3 className={styles.sectionTitle}>Our Address</h3>
                                <Row alignTop={true} className={`flex-1 ${styles.sectionContent}`}>
                                    <div className={styles.iconWrapper}><FontAwesomeIcon icon={faHome} size="lg" /></div>
                                    <Col>
                                        <p className={styles.addressWrapper}>{data.allStrapiContact.edges[0].node.address1} {data.allStrapiContact.edges[0].node.address2}</p>
                                        <p>{data.allStrapiContact.edges[0].node.postalCode}</p>
                                    </Col>
                                </Row>
                                <Row alignTop={true} className="flex-1">
                                    <div className={styles.iconWrapper}><FontAwesomeIcon icon={faPhoneAlt} size="lg" /></div>
                                    <p>{data.allStrapiContact.edges[0].node.phone}</p>
                                </Row>
                            </Col>
                            <Col className={styles.footerColWrapper}>
                                <h3 className={styles.sectionTitle}>Connect with Us</h3>
                                <Row alignTop={true} className={`flex-1 ${styles.sectionContent}`}>
                                    <div className={styles.iconWrapper}><FontAwesomeIcon icon={faQuestionCircle} size="lg" /></div>
                                    <Link className={styles.enquiryLink} to="/enquiry">
                                        <p>Enquiry</p>
                                    </Link>
                                </Row>
                                <p className={styles.sectionContent}>&copy; 2020 All Ins Agency Pte Ltd</p>
                                <p className={styles.termsOfUse}>
                                    <Link className={styles.sitemapContent} to="/privacy">Privacy Policy</Link> | <Link className={styles.sitemapContent} to="/terms">Terms of Use</Link>
                                </p>
                            </Col>
                        </ResponsiveRow>
                    </InnerWrapper>
                </div>
            )
        }}
    />
);

export default Footer;
