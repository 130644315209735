import React from 'react';

export default ({ children, className = '' }) => {
    return (
        <div className={className}>
            {children}
        </div>
    );
}

export const SectionWrapper = ({ children, className = "" }) => {
    return (
        <section className={`
            flex flex-1 flex-col items-center bg-white ${className}`}>
            {children}
        </section>
    )
};

export const SectionWrapperGray = ({ children, className = "" }) => (
    <section className={`flex flex-1 flex-col items-center bg-primary-lighter ${className}`}>
        {children}
    </section>
)

export const SectionInnerWrapper = ({ children, className = "" }) => {
    return (
        <div className={`container px-4 mx-auto pt-24 pb-20 md:w-md lg:w-lg xl:w-xl ${className}`}>
            {children}
        </div>
    );
}

export const InnerWrapper = ({ children, className = "" }) => {
    return (
        <div className={`container px-4 mx-auto md:w-md lg:w-lg xl:w-xl ${className}`}>
            {children}
        </div>
    );
}

export const Row = ({ children, className, alignTop }) => {
    return (
        <div className={`flex flex-row ${alignTop ? "" : "items-center"} ${className}`}>
            {children}
        </div>
    );
}

export const Col = ({ children, className = "" }) => {
    return (
        <div className={`flex flex-1 flex-col items-start ${className}`}>
            {children}
        </div>
    );
}

export const ResponsiveRow = ({ children, className = "", breakpoint = "md", alignTop }) => {
    return (
        <div className={`flex flex-col md:flex-row ${alignTop ? "" : `md:items-center`} ${className}`}>
            {children}
        </div>
    );
}

export const AlignRight = ({ children, className = '' }) => {
    return (
        <div className={`flex justify-end ${className}`}>
            {children}
        </div>
    );
}

export const InvisibleOnMobile = ({ children, className = "" }) => {
    return (
        <div className={`hidden lg:flex ${className}`}>
            {children}
        </div>
    );
}

export const VisibleOnMobile = ({ children, className = "" }) => {
    return (
        <div className={`lg:hidden ${className}`}>
            {children}
        </div>
    );
}

export const SectionSubHeader = ({ children }) => {
    return (
        <div className="flex flex-row items-center">
            <p className="mb-0 font-medium text-sm md:text-md lg:text-lg uppercase tracking-wide text-secondary-darker">
                {children}
            </p>
            <div className="block bg-primary ml-6 px-4 py-px"></div>
        </div>
    );
}

export const SectionHeader = ({ children }) => {
    return (
        <h3 className="text-3xl md:text-4xl lg:text-6xl font-bold uppercase tracking-wide text-secondary-darker">
            {children}
        </h3>
    );
}

export const CommonContentWrapper = ({ children, className }) => {
    return (
        <div className={`leading-relaxed font-sans text-base text-secondary-default mt-4 ${className}`}>
            {children}
        </div>
    );
}

export const CommonContentDivider = () => {
    return (
        <div className="h-16"></div>
    );
}

export const SmallDivider = () => {
    return (
        <div className="h-8"></div>
    );
}