import React from 'react';
import Footer from './Footer';
import Header from './Header';
import FabButton from '../FabButton';

const Layout = ({ children }) => {
  return (
    <>
      <Header />
      <main className="text-gray-900 w-full">
        {children}
        <FabButton />
      </main>
      <Footer />
    </>
  );
};

export default Layout;
