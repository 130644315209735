import React from "react"
import { StaticQuery, graphql } from "gatsby"
import { Fab, Action } from "react-tiny-fab"
import "react-tiny-fab/dist/styles.css"

import Icon from "./Icon"

const FabButton = () => {
    return (
        <StaticQuery
            query={graphql`
                query FabButtonQuery {
                    allStrapiContact {
                        edges {
                            node {
                                phone
                                whatsappDefaultText
                            }
                        }
                    }
                }
            `}
            render={data => {
                const phone = parseInt(data.allStrapiContact.edges[0].node.phone.replace(/\D/g, ''));
                const text = data.allStrapiContact.edges[0].node.whatsappDefaultText || "";

                return (
                    <Fab
                        mainButtonStyles={{ backgroundColor: "#368AEA", color: "#FFFFFF" }}
                        icon={<Icon type="fas" name="phone" size="1x" />}
                        position={{ bottom: 4, right: 2 }}
                        event="click"
                    >
                        <Action
                            style={{ backgroundColor: "#FFAC4E", color: "#FFFFFF" }}
                            text="Call"
                            onClick={() => window.open(`tel:${phone}`, "_blank")}
                        >
                            <Icon type="fas" name="phone-volume" size="2x" />
                        </Action>
                        <Action
                            style={{ backgroundColor: "#2ECC71", color: "#FFFFFF" }}
                            text="Whatsapp"
                            onClick={() => window.open(`https://api.whatsapp.com/send?phone=${phone}&text=${text}`, "_blank")}
                        >
                            <Icon type="fab" name="whatsapp" size="2x" />
                        </Action>
                    </Fab>
                );
            }}
        />
    );
}

export default FabButton