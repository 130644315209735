import React from 'react';
import { Link } from "gatsby";

import Icon from "./Icon"

const sizes = {
  default: `py-3 px-8`,
  xs: `py-2 px-4 text-sm`,
  sm: `py-3 px-6 text-sm`,
  lg: `py-4 px-12`,
  xl: `py-5 px-16 text-lg`
};

const fonts = {
  sans: `font-sans`,
  mono: `font-mono`,
  serif: `font-serif`,
}

const colors = {
  default: `bg-primary hover:bg-primary-darker text-white`,
  dark: `bg-secondary hover:bg-secondary-darker text-white`,
  light: `bg-white hover:bg-primary-lighter text-primary`,
}

const Button = ({ children, className = '', size, type, font, color, onClick }) => {
  return (
    <button
      type={type || "button"}
      className={`
        ${sizes[size] || sizes.default}
        ${colors[color] || colors.default}
        ${fonts[font] || ''}
        ${className}
        rounded-sm
      `}
      onClick={onClick && onClick}
    >
      {children}
    </button>
  );
};

export default Button;

const iconColors = {
  default: `text-primary hover:text-white`,
  dark: `text-secondary hover:text-secondary-darker`,
  light: `text-secondary-lighter hover:text-primary`,
}

export const IconButton = ({ className = "", to, size, type, color, icon }) => {
  return (
    <Link className={`${iconColors[color] || iconColors.default} ${className}`} to={to || "/"}>
      <Icon type={type} name={icon} size={size} />
    </Link>
  );
}

export const ShareIconButton = ({ className = "", to, size, color, type, icon }) => {
  return (
    <Icon type={type} name={icon} size={size} />
  );
}