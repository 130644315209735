/** @format */

import React, { useState } from 'react';
import { StaticQuery, graphql, Link } from 'gatsby';

import LogoIcon from '../../svg/LogoIcon';
import Button from '../Button';
import Icon from '../Icon';
import { Row, InvisibleOnMobile, VisibleOnMobile, AlignRight } from '../Wrapper.js';
import styles from './header-css-modules.module.css';

import { menu } from '../../data/menu-data';

const Header = () => {
    const [category, setCategory] = useState(0);
    const [mobileMenuOpended, setMobileMenuOpened] = useState(false);

    return (
        <StaticQuery
            query={graphql`
                query HeaderQuery {
                    allStrapiProductcategory(sort: { fields: fields___sortindexnum, order: ASC }) {
                        edges {
                            node {
                                fields {
                                    sortindexnum
                                }
                                title
                            }
                        }
                    }
                    allStrapiProduct(sort: { fields: fields___sortindexnum, order: ASC }) {
                        edges {
                            node {
                                id
                                name
                                category
                                icontype
                                iconname
                                numofpeople
                                location
                                payment
                                risklevel
                                support
                                motorenquiry
                                showonhomepage
                                body
                                fields {
                                    sortindexnum
                                }
                            }
                        }
                    }
                    allStrapiContact {
                        edges {
                            node {
                                phone
                            }
                        }
                    }
                    site {
                        siteMetadata {
                            apiUrl
                        }
                    }
                }
            `}
            render={(data) => {
                const productsByCat = data.allStrapiProductcategory.edges.map((c) => {
                    let arr = [];

                    data.allStrapiProduct.edges.map((p) => {
                        if (p.node.category === c.node.title) arr.push(p.node);
                    });

                    return arr;
                });

                return (
                    <header className={styles.headerWrapper}>
                        <div className={styles.topBarWrapper}>
                            <div className={styles.contactWrapper}>
                                <Row className="flex-1 h-full">
                                    <InvisibleOnMobile>
                                        <p className={styles.phoneWrapper}>
                                            Phone:{' '}
                                            <span className={styles.phoneNumber}>
                                                {data.allStrapiContact.edges[0].node.phone}
                                            </span>
                                        </p>
                                    </InvisibleOnMobile>
                                    <Row className="flex-1 justify-center lg:justify-end">
                                        {/* <div className={styles.iconDividerLeft}></div>
                                            <IconButton className="px-6 py-2" color="light" icon="facebook" to="/" />
                                            <div className={styles.iconDividerMiddle}></div>
                                            <IconButton className="px-6 py-2" color="light" icon="instagram" to="/" />
                                            <div className={styles.iconDividerRight}></div> */}
                                        <Link to="/search">
                                            <Button size="xs" font="serif">
                                                Search
                                            </Button>
                                        </Link>
                                    </Row>
                                </Row>
                            </div>
                        </div>
                        <div className={styles.menuBarWrapper}>
                            <Row className="flex-1 h-full items-center">
                                <LogoIcon />
                                <AlignRight className="flex-1 h-full items-center">
                                    <nav className="flex h-full items-center">
                                        <VisibleOnMobile>
                                            <a
                                                className={styles.mobileMenuButton}
                                                onClick={() => setMobileMenuOpened(!mobileMenuOpended)}>
                                                <Icon type="fas" name="bars"></Icon>
                                            </a>
                                            <div
                                                className={`${mobileMenuOpended ? 'block' : 'hidden'} ${
                                                    styles.mobileMenuDropdown
                                                }`}>
                                                {menu.map((m, i) => (
                                                    <Link key={i} to={m.link} className={styles.mobileMenuList}>
                                                        <Row>
                                                            {m.title}
                                                            <div className={styles.mobileMenuListArrow}>
                                                                <Icon type="fas" name="chevron-right"></Icon>
                                                            </div>
                                                        </Row>
                                                    </Link>
                                                ))}
                                            </div>
                                        </VisibleOnMobile>
                                        <InvisibleOnMobile className="flex flex-1 h-full items-center">
                                            <div className={styles.menuWrapper}>
                                                {menu.map((m, i) => (
                                                    <div key={i} className={styles.menuButtonWrapper}>
                                                        <div className="h-full">
                                                            {m.link ? (
                                                                <Link className={styles.menuButton} to={m.link}>
                                                                    {m.title}
                                                                </Link>
                                                            ) : (
                                                                <div className={styles.menuButton}>{m.title}</div>
                                                            )}
                                                            {m.showList && m.subMenu && (
                                                                <ul className={styles.dropDownMenu}>
                                                                    {m.subMenu.map((sm, smi) => (
                                                                        <li
                                                                            key={smi}
                                                                            className={styles.dropDownMenuList}>
                                                                            <Link to={sm.link}>{sm.title}</Link>
                                                                        </li>
                                                                    ))}
                                                                </ul>
                                                            )}
                                                        </div>
                                                        {m.showTable && m.subMenu && (
                                                            <div className={styles.dropDownMenuTable}>
                                                                <Row alignTop={true}>
                                                                    <div className="flex flex-col justify-center">
                                                                        <ul className={styles.dropDownMenuCategory}>
                                                                            {productsByCat.map((p, pi) => (
                                                                                <li
                                                                                    key={pi}
                                                                                    className={
                                                                                        pi === category
                                                                                            ? styles.active
                                                                                            : ''
                                                                                    }>
                                                                                    {p.length > 1 ? (
                                                                                        <a
                                                                                            onClick={() =>
                                                                                                setCategory(pi)
                                                                                            }>
                                                                                            {
                                                                                                data
                                                                                                    .allStrapiProductcategory
                                                                                                    .edges[pi].node
                                                                                                    .title
                                                                                            }
                                                                                        </a>
                                                                                    ) : (
                                                                                        <a
                                                                                            href={`/product?id=${p[0].id}`}>
                                                                                            {
                                                                                                data
                                                                                                    .allStrapiProductcategory
                                                                                                    .edges[pi].node
                                                                                                    .title
                                                                                            }
                                                                                        </a>
                                                                                    )}
                                                                                </li>
                                                                            ))}
                                                                        </ul>
                                                                    </div>
                                                                    <div className="flex flex-col">
                                                                        <div className={styles.dropDownMenuItem}>
                                                                            <div className="grid grid-cols-3 row-gap-4 col-gap-4">
                                                                                {productsByCat.map(
                                                                                    (p, pi) =>
                                                                                        (pi === category ||
                                                                                            category === '') &&
                                                                                        p.map((item, ii) => (
                                                                                            <div key={ii}>
                                                                                                <a
                                                                                                    className={
                                                                                                        styles.dropDownMenuItemLink
                                                                                                    }
                                                                                                    href={`/product?id=${item.id}`}>
                                                                                                    {item.name}
                                                                                                </a>
                                                                                            </div>
                                                                                        ))
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </Row>
                                                            </div>
                                                        )}
                                                        <div className={styles.menuButtonBar}></div>
                                                    </div>
                                                ))}
                                            </div>
                                        </InvisibleOnMobile>
                                    </nav>
                                </AlignRight>
                            </Row>
                        </div>
                    </header>
                );
            }}
        />
    );
};

export default Header;
