import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { library } from "@fortawesome/fontawesome-svg-core"
import { fas } from "@fortawesome/free-solid-svg-icons"
import { far } from "@fortawesome/free-regular-svg-icons"
import { fab } from "@fortawesome/free-brands-svg-icons"

library.add(fas, far, fab);

const Icon = ({ type, name, size = "1x" }) => (
    type === "icon" ? 
        <i className={`${type} ${name}`}></i>
    :
        <FontAwesomeIcon icon={[type, name]} size={size} />
);

export default Icon;