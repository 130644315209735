import React from "react"
import { Helmet } from "react-helmet"
import PropTypes from "prop-types"

const SEO = ({ title, description, url }) => (
    <Helmet title={title}>
        <meta name="description" content={description} />
        {url && <meta property="og:url" content={url} />}
        {title && <meta property="og:title" content={title} />}
        {description && (
            <meta property="og:description" content={description} />
        )}
    </Helmet>
)

export default SEO

SEO.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    pathname: PropTypes.string,
}

SEO.defaultProps = {
    title: null,
    description: null,
    pathname: null,
}